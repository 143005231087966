* {
  font-family: 'DM Sans', sans-serif !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plzDropdown {
  position: absolute;
  top: 4rem; 
  left: 50;
  background-color: #fff;
  width: 100%;
  max-height: 200px;
  overflow-y: auto; 
  border: 1px solid #EFF0F6;
  border-radius: 10px; 
  box-shadow: 0 8px 25px 0 rgba(13, 10, 44, 0.06);
  z-index: 1000;
}

.selectOptionStyle {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.selectOptionStyle:hover {
  background-color: #f5f5f5; 
}

.selectOptionStyle:not(:last-child) {
  border-bottom: 1px solid #ccc; 
}


